<template>
  <b-modal
    id="profile-modal"
    :active="true"
    :can-cancel="['escape', 'outside']"
    :has-modal-card="true"
    :width="400"
    scroll="keep"
    @close="close"
  >
    <div class="modal-card">
      <div class="modal-card-head is-paddingless">
        <div class="is-stretched">
          <article class="media has-padding-150 has-text-white">
            <figure class="media-left is-hidden-mobile">
              <avatar
                :name="$store.getters['user/name']()"
                :avatar="$store.getters['user/avatarUrl']()"
                :size="80"
              />
            </figure>

            <div class="media-content">
              <p>
                <strong>{{ $store.getters["user/name"]() }}</strong>
              </p>

              <p>{{ $store.getters["user/email"]() }}</p>

              <p>
                <router-link
                  :to="{ path: '/account/profile' }"
                  class="has-text-white"
                  @click.native="close"
                >
                  <u>My account</u>
                </router-link>
                <span>&nbsp;|&nbsp;</span>
                <router-link
                  :to="{ name: 'logout' }"
                  class="has-text-white"
                  @click.native="close"
                >
                  <u>Logout</u>
                </router-link>
              </p>
            </div>
          </article>

          <div class="has-background-white">
            <horizontal-nav
              :routes="routes"
              :router-links="false"
              :active-route="activeRoute"
              @changed="activeRoute = $event"
            />
          </div>
        </div>
      </div>

      <div class="modal-card-body has-padding-100">
        <notifications />
      </div>

      <div class="modal-card-foot">
        <a class="has-text-grey" @click="close">Close</a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ProfileModal",
  components: {
    notifications: require("@shared/notifications/notifications").default
  },
  data() {
    return {
      routes: [
        {
          label: "Notifications"
        }
      ],
      activeRoute: null
    };
  },
  created() {
    this.activeRoute = this.routes[0];
  },
  methods: {
    close() {
      this.$store.commit("ui/profileModal", false);
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

#profile-modal {
  .modal-card-head {
    background-color: $primary;
    background-color: var(--primary-color);
  }

  @include tablet {
    align-items: flex-end;
    .animation-content {
      margin: 0 1.5em;
      .modal-card {
        height: 100vh;
        max-height: calc(100vh - 5rem);
      }
    }
  }
}
</style>
