<template>
  <div
    id="breadcrumbs"
    :class="{
      'is-administrating': administrating,
      'has-background-warning': $projectId === 'fixed-dev'
    }"
  >
    <div class="container">
      <p>
        <template v-for="(route, index) in breadcrumbs">
          {{ index > 0 ? " / " : "" }}
          <breadcrumb :key="`breadcrumb-crumb-${index}`" :route="route" />
        </template>
      </p>
    </div>
  </div>
</template>

<script>
let Breadcrumb = {
  name: "Breadcrumb",
  props: {
    route: { required: true }
  },

  template: `<router-link v-if="route.meta.breadcrumb.getter" :to="destination" class="has-text-weight-semibold">

                <template v-if="value">{{ label }}</template>

                <template v-else>&hellip;</template>

            </router-link>

            <router-link v-else :to="destination" class="has-text-weight-semibold">{{ label }}</router-link>`,

  computed: {
    destination() {
      let path = this.route.path;

      this.route.regex.keys.forEach(key => {
        path = path.replace(`:${key.name}`, this.$route.params[key.name]);
      });

      return { path: path };
    },

    value() {
      if (!this.route.meta.breadcrumb.getter) {
        return null;
      }

      return this.$store.getters[this.route.meta.breadcrumb.getter](
        this.$route.params[this.route.meta.breadcrumb.param]
      );
    },

    label() {
      if (typeof this.route.meta.breadcrumb.label === "function") {
        return this.route.meta.breadcrumb.label(this.value);
      }

      return this.route.meta.breadcrumb.label;
    }
  }
};

export default {
  name: "Breadcrumbs",
  components: {
    breadcrumb: Breadcrumb
  },
  computed: {
    administrating() {
      return this.$route.path.startsWith("/admin");
    },
    breadcrumbs() {
      return this.$_.filter(this.$route.matched, route => {
        return route.meta.breadcrumb;
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

#breadcrumbs {
  grid-row: 3;
  grid-column: 1 / 4;
  color: $primary-invert;

  &:not(.is-administrating) {
    background-color: $primary;
    background-color: var(--primary-color);
  }

  &.is-administrating {
    background-color: $dark;
  }

  div.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;

    @include touch {
      padding: 0 1rem;
    }
    @include desktop {
      padding: 0 0.5rem;
    }
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    margin-right: 1.5em;

    a {
      color: $primary-invert;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
