<template>
  <transition name="menu">
    <div v-if="showMenu" :class="{ 'is-mobile': isMobile }">
      <router-link
        v-if="isMobile && !isWhitelabeled"
        :to="{ name: 'sites' }"
        class="is-hidden-tablet"
      >
        <img
          :style="{ height: `40px`, width: `auto` }"
          src="/assets/images/interface/logos/fixed-reverse.svg"
          class="is-block"
        />
      </router-link>
      <span v-else />

      <nav>
        <router-link
          v-for="route in filteredRoutes"
          :key="$_.camelCase(route.label)"
          :to="route.to"
          >{{ route.label }}</router-link
        >
      </nav>

      <a
        v-if="isMobile"
        class="is-hidden-tablet"
        @click="$store.commit('ui/menu', false)"
      >
        <b-icon icon="times" />
      </a>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Menu",
  data() {
    return {
      routes: [
        {
          label: "Admin",
          to: { path: "/admin" },
          guard: () => {
            return this.user.isAgent;
          }
        },
        {
          label: "Reseller",
          to: { path: "/reseller" },
          guard: () => {
            return this.user.isReseller;
          }
        },
        {
          label: "My sites",
          to: { path: "/sites" }
        },
        {
          label: "My tasks",
          to: { path: "/tasks" }
        },
        {
          label: "My account",
          to: { path: "/account" }
        },
        {
          label: "Affiliate program",
          to: { name: "affiliateProgram" },
          guard: () => !this.isWhitelabeled
        },
        {
          label: "Logout",
          to: { path: "/logout" },
          guard: () => {
            return this.isMobile;
          }
        }
      ]
    };
  },
  computed: {
    ...mapState({
      menu: state => state.ui.menu,
      window: state => state.ui.window
    }),
    user() {
      return this.$store.getters["user/user"]();
    },
    isMobile() {
      return this.window.width <= 768;
    },
    isWhitelabeled() {
      return this.$store.getters["ui/isWhitelabeled"]();
    },
    showMenu() {
      return this.isMobile ? this.menu : true;
    },
    filteredRoutes() {
      return this.$_(this.routes)
        .filter(route => {
          return route.guard ? route.guard() : true;
        })
        .value();
    }
  },
  watch: {
    "window.width"() {
      if (!this.isMobile && this.menu) {
        this.$store.commit("ui/menu", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
div {
  @include mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 320px;
    background: linear-gradient(to top, rgba($dark, 0.95) 0, $black);
    z-index: 10;
    opacity: 1;
    visibility: hidden;
    transition: opacity ease-out 0.15s, transform 0s 0.15s;
    transform: translateY(0%);

    &.is-mobile {
      visibility: visible;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 3rem 1rem;
      overflow: auto;
    }

    a {
      color: $dark-invert;
      &:not(.router-link-active):hover {
        opacity: 0.75;
      }
      &.router-link-active {
        text-decoration: underline;
      }
    }

    nav {
      padding: 1.5rem;
      a {
        display: block;
        flex-basis: 100%;
        text-align: center;
        font-family: $family-custom;
        font-size: $size-4;
        font-weight: 500;
        margin-top: 1rem;
      }
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="menu" when their visibility is toggled
    * by Vue.js.
    */

    &.menu-enter-to {
      transition: opacity ease-out 0.2s, transform ease-out 0.2s;
    }

    &.menu-enter,
    &.menu-leave-to {
      opacity: 0;
      transform: translateY(100vh);
    }
  }
  @include tablet {
    a {
      font-family: $family-custom;
      font-size: 17px;
      line-height: 1em;
      font-weight: 500;
      color: $dark;
      margin-right: 1rem;

      &.router-link-active {
        color: $primary;
        color: var(--primary-color);
        border-bottom: 2px solid;
      }
    }
  }
}
</style>
