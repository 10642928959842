<template>
  <b-modal
    :active="true"
    :can-cancel="[]"
    :width="600"
    scroll="keep"
    has-modal-card
  >
    <form novalidate @submit.prevent="saveDetails">
      <div class="modal-card">
        <header class="modal-card-head has-background-info">
          <p class="modal-card-title has-text-white has-text-centered">
            Update your details
          </p>
        </header>

        <section class="modal-card-body">
          <b-field class="has-margin-bottom-150">
            <div class="message is-info">
              <p v-if="isWhitelabeled" class="message-body">
                To ensure we deliver you the best possible experience, please
                take a second to
                <strong>review and update your personal details</strong>.
              </p>
              <p v-else class="message-body">
                <strong>Everyone hates spam – including us!</strong> To ensure
                you only receive emails you actually care about, please take a
                second to review and update your contact preferences.
              </p>
            </div>
          </b-field>

          <b-field label="Name *">
            <b-input
              v-model="form.name"
              type="input"
              placeholder="Enter your name..."
              expanded
              required
            />
          </b-field>

          <b-field label="Email *">
            <b-input
              :value="form.email"
              type="email"
              placeholder="Enter your email..."
              disabled
              expanded
              required
            />
          </b-field>

          <div v-if="!isWhitelabeled">
            <b-field class="has-content-justified-between has-margin-top-150">
              <p class="control">
                Keep me informed on product information and fixed.net news
              </p>
              <b-switch v-model="form.sendProductInformation" type="is-info" />
            </b-field>

            <b-field class="has-content-justified-between">
              <p class="control">
                Keep me informed on service status and outages
              </p>
              <b-switch v-model="form.sendStatusReports" type="is-info" />
            </b-field>

            <b-field class="has-content-justified-between">
              <p class="control">Send me exclusive offers</p>
              <b-switch v-model="form.sendOffers" type="is-info" />
            </b-field>
          </div>
        </section>

        <footer class="modal-card-foot has-content-justified-end">
          <button
            :class="{ 'is-loading': processing }"
            :disabled="!validForm || processing"
            type="submit"
            class="button is-success"
          >
            Save details
          </button>
        </footer>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: "GDPRModal",
  data() {
    return {
      processing: false,
      form: {}
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    validForm() {
      if (!this.form.name.trim().length) return false;
      if (!this.form.email.trim().length) return false;
      return true;
    },
    isWhitelabeled() {
      return this.$store.getters["ui/isWhitelabeled"]();
    }
  },

  created() {
    this.$set(this, "form", {
      name: this.user.name || "",
      email: this.user.email,
      sendOffers: this.$_.isBoolean(this.user.sendOffers)
        ? this.user.sendOffers
        : false,
      sendProductInformation: this.$_.isBoolean(
        this.user.sendProductInformation
      )
        ? this.user.sendProductInformation
        : false,
      sendStatusReports: this.$_.isBoolean(this.user.sendStatusReports)
        ? this.user.sendStatusReports
        : false
    });
  },

  methods: {
    saveDetails() {
      this.processing = true;
      this.$store
        .dispatch("user/saveDetails", {
          name: this.form.name,
          email: this.form.email,
          sendOffers: this.form.sendOffers,
          sendProductInformation: this.form.sendProductInformation,
          sendStatusReports: this.form.sendStatusReports
        })
        .then(() => {
          this.$toast.open({
            message: `Details updated`,
            position: "is-bottom",
            type: "is-success",
            queue: false
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
