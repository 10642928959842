<template>
  <loading v-if="loading" />

  <div v-else>
    <no-results
      v-if="!notificationsData.length"
      icon="bell"
      title="No notifications"
      message="You have no new notifications."
    />

    <notification
      v-for="notification in notificationsData"
      v-else
      :key="notification._id"
      :message="notification.message"
      :date-created="notification.dateCreated.toDate()"
      :read="notification.read"
      :cta="notification.cta || null"
      @dismissed="markNotificationAsRead(notification)"
      @ctaClicked="onCtaClicked(notification)"
    />

    <load-more
      v-if="!pagination.complete"
      :loading="pagination.loading"
      @loadMore="getNotifications"
    />
  </div>
</template>

<script>
import { collection, orderBy, query, where } from "@firebase/firestore";
import { mapState } from "vuex";

export default {
  name: "Notifications",
  data() {
    return {
      loading: true,
      pagination: {
        limit: 4,
        loading: false,
        complete: true
      },
      notifications: [],
      notificationsOriginal: []
    };
  },
  computed: {
    ...mapState({
      observedNotifications: state => state.user.notifications.observed
    }),
    user() {
      return this.$store.getters["user/user"]();
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    notificationsData() {
      return this.$_(this.observedNotifications)
        .toArray()
        .unionBy(this.notifications, i => i._id)
        .orderBy(["read", "dateCreated.seconds"], ["asc", "desc"])
        .value();
    },
    cursor() {
      return this.notificationsOriginal.length
        ? this.$_.last(this.notificationsOriginal)
        : null;
    }
  },
  created() {
    this.getNotifications();
  },
  methods: {
    markNotificationAsRead(notification) {
      this.$store
        .dispatch("user/markNotificationAsRead", { notification })
        .then(() => {
          notification.read = true;
          this.notifications.push(notification);
        });
    },
    getNotifications() {
      this.pagination.loading = true;
      this.$store
        .dispatch("user/getNotifications", {
          ref: query(
            collection(this.$firestore, `users/${this.userId}/notifications`),
            where("read", "==", true),
            orderBy(`dateCreated`, `desc`)
          ),
          limit: this.pagination.limit,
          cursor: this.cursor
        })
        .then(({ notifications, complete }) => {
          this.notificationsOriginal = this.notificationsOriginal.concat(
            notifications
          );
          this.notifications = this.$_.map(
            this.notificationsOriginal,
            notification =>
              this.$_.merge({}, notification.data(), { _id: notification.id })
          );

          this.pagination.complete = complete;
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.pagination.loading = false;
          this.loading = false;
        });
    },
    onCtaClicked(notification) {
      this.$store.commit("ui/profileModal", false); // Close profile modal
      this.markNotificationAsRead(notification); // and dismiss the notification
    }
  }
};
</script>
