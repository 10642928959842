import { render, staticRenderFns } from "./contentPane.vue?vue&type=template&id=12473972&scoped=true"
import script from "./contentPane.vue?vue&type=script&lang=js"
export * from "./contentPane.vue?vue&type=script&lang=js"
import style0 from "./contentPane.vue?vue&type=style&index=0&id=12473972&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12473972",
  null
  
)

export default component.exports