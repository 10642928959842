<template>
  <header id="header">
    <div class="container">
      <div id="header-left-controls" class="is-hidden-tablet">
        <a class="button is-white" @click.prevent="$store.commit('ui/menu')">
          <b-icon :icon="$store.state.ui.menu ? 'times' : 'bars'" pack="fas" />
        </a>
      </div>

      <div id="header-logo">
        <router-link :to="{ name: 'sites' }">
          <span v-if="isResellerUser">
            <img
              v-if="base64Logo"
              :style="{ height: `40px`, width: `auto` }"
              :src="base64Logo"
              class="is-block"
            />
            <h1 v-else class="has-text-dark is-size-5">
              <strong>{{
                $store.getters["reseller/name"](user.resellerId)
              }}</strong>
            </h1>
          </span>

          <img
            v-else
            :style="{ height: `40px`, width: `auto` }"
            src="/assets/images/interface/logos/fixed.svg"
            class="is-block"
          />
        </router-link>
      </div>

      <div id="header-right-controls">
        <app-menu />

        <notifications-dropdown
          v-if="window.width > 768"
          class="is-hidden-mobile has-margin-right-50"
        />

        <avatar
          :name="$store.getters['user/name']()"
          :avatar="$store.getters['user/avatarUrl']()"
          :size="48"
          @click="$store.commit('ui/profileModal', true)"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  components: {
    "app-menu": () =>
      import(/* webpackChunkName: "authenticated" */ "@shared/ui/menu"),
    "notifications-dropdown": () =>
      import(
        /* webpackChunkName: "authenticated" */ "@shared/notifications/notificationsDropdown"
      )
  },
  data() {
    return {
      logoPath: null
    };
  },
  computed: {
    ...mapState({
      window: state => state.ui.window
    }),
    logoUrl() {
      return this.$store.getters.cached({ key: this.logoPath });
    },
    user() {
      return this.$store.getters["user/user"]();
    },
    isResellerUser() {
      return this.$store.state.auth.claims.isResellerUser;
    },
    reseller() {
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    },
    base64Logo() {
      return this.$_.get(this.reseller, "logo", null)
        ? `data:${this.reseller.logo.contentType};base64,${this.reseller.logo.base64}`
        : null;
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

#header {
  grid-row: 2;
  grid-column: 1 / 4;
  position: relative;
  box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.15);
  background: white;

  & > div.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;

    @include touch {
      padding: 0 1rem;
    }
    @include desktop {
      padding: 0 0.5rem;
    }

    & > div {
      height: 3rem;
      min-width: 3rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
