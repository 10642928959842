<template>
  <b-notification
    v-if="alert.isEnabled"
    :active="isActive"
    :type="alert.type"
    @close="isActive = false"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p v-html="alert.message" />
  </b-notification>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Alert",
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    ...mapState({
      ui: state => state.ui
    }),
    alert() {
      return {
        isEnabled: false,
        type: "is-warning",
        message: "",
        ...this.$_.get(this.ui, "tenant.alert", {})
      };
    }
  },
  watch: {
    alert: {
      handler(newAlert, oldAlert) {
        const newMessage = this.$_.get(newAlert, "message");
        const oldMessage = this.$_.get(oldAlert, "message");
        if (newMessage !== oldMessage) this.isActive = true;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.notification {
  grid-row: 1;
  grid-column: 1 / 4;
  margin: 0;
}
</style>
