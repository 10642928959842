<template>
  <b-dropdown
    ref="notificationDropdown"
    :mobile-modal="false"
    position="is-bottom-left"
  >
    <span slot="trigger" class="notifications-trigger">
      <b-icon icon="bell" pack="fas" size="is-small" />
      <pulse v-if="hasNotifications" />
    </span>

    <b-dropdown-item :style="{ width: `360px` }" custom>
      <loading v-if="loading" />
      <no-results
        v-if="!hasNotifications"
        icon="bell"
        title="No notifications"
        message="You have no new notifications."
      />
      <notification
        v-for="notification in orderedNotifications"
        v-else
        :key="notification._id"
        :message="notification.message"
        :date-created="notification.dateCreated.toDate()"
        :read="notification.read"
        :cta="notification.cta || null"
        @dismissed="markNotificationAsRead(notification)"
        @ctaClicked="onCtaClicked(notification)"
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NotificationsDropdown",
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState({
      notifications: state => state.user.notifications.observed
    }),
    orderedNotifications() {
      return this.$_(this.notifications)
        .filter({ read: false })
        .orderBy(i => i.dateCreated.toDate(), ["desc"])
        .value();
    },
    hasNotifications() {
      return !this.$_.isEmpty(this.orderedNotifications);
    }
  },
  mounted() {
    this.$bus.$on("notifications/toggle", show => {
      this.$nextTick(() => {
        if (this.$refs.notificationDropdown.isActive !== show) {
          this.$refs.notificationDropdown.toggle();
        }
      });
    });
  },
  beforeDestroy() {
    this.$bus.$off("notifications/toggle");
  },
  methods: {
    markNotificationAsRead(notification) {
      this.$store.dispatch("user/markNotificationAsRead", {
        notification
      });
    },
    onCtaClicked(notification) {
      this.$bus.$emit("notifications/toggle", false);
      this.markNotificationAsRead(notification);
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.notifications-trigger {
  display: block;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;
  .pulse {
    position: absolute;
    bottom: 50%;
    left: 55%;
  }
}
.dropdown-content {
  overflow-y: auto;
  max-height: 80vh;
}
</style>
