<template>
  <main id="content-pane">
    <loading v-if="routing" :delay="200" />
    <div v-else>
      <router-view :key="key" />
      <copyright />
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ContentPane",
  components: {
    copyright: () =>
      import(/* webpackChunkName: "core" */ "@skeleton/copyright")
  },
  computed: {
    ...mapState({
      routing: state => state.ui.routing
    }),
    key() {
      let key = null;
      let matched = this.$_.clone(this.$route.matched);
      this.$_.each(matched.reverse(), route => {
        if (this.$_.has(route, "meta.key")) {
          key = route.meta.key(this.$route.params);
          return false;
        }
      });
      return key;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

#content-pane {
  grid-row: 4;
  grid-column: 1 / span 2;
  height: calc(100vh - 6.5rem);
  background: $light;
  position: relative;
  padding-bottom: 6.5rem;

  @include is-scrollable;

  & > .custom-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
