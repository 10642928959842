<template>
  <div>
    <div id="container">
      <alert />
      <header-pane />
      <breadcrumbs />
      <content-pane />
    </div>
    <profile-modal v-if="ui.profileModal" />
    <gdpr-modal v-if="missingUserData() && accountHasAged" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "LoggedIn",

  components: {
    "header-pane": () =>
      import(/* webpackChunkName: "authenticated" */ "@shared/ui/header"),
    alert: () =>
      import(/* webpackChunkName: "authenticated" */ "@shared/ui/alert"),
    breadcrumbs: () =>
      import(/* webpackChunkName: "authenticated" */ "@shared/ui/breadcrumbs"),
    "profile-modal": () =>
      import(
        /* webpackChunkName: "authenticated" */ "@shared/account/profileModal"
      ),
    "content-pane": () =>
      import(/* webpackChunkName: "authenticated" */ "@shared/ui/contentPane"),
    "gdpr-modal": () =>
      import(/* webpackChunkName: "authenticated" */ "./gdprModal")
  },

  computed: {
    ...mapState({
      ui: state => state.ui
    }),
    ...mapGetters({
      user: "user/user",
      missingUserData: "user/missingUserData"
    }),
    accountHasAged() {
      // Check account is older than two hours
      let twoHoursAgo = new Date();
      twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);
      if (!this.user().dateCreated) return false;
      if (this.user().dateCreated.toDate() > twoHoursAgo) return false;
      return true;
    }
  },

  mounted() {
    this.$emit("mounted");
  }
};
</script>

<style scoped lang="scss">
@import "~@sass/bulma/custom-variables";

#container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: grid;
  // Omitted 'grid-template-rows' in favour of specify height on a per-component-basis, as 1fr was breaking scroll on certain iOS devices
  // grid-template-rows: 64px 40px 1fr;
  grid-template-columns: 1fr;
  grid-gap: 0;
  transition-duration: 0.2s;
  min-width: 20em;
}
</style>
